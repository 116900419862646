<template>
    <div>

        <div class="lg:pt-[100px] pt-[20px] p-4 lg:w-3/4 w-full flex flex-col justify-center items-center mb-16 mx-auto">
            <h1 class="text-xl">Πρωταθλήματα ομάδων ανα χρονιά</h1>
            <div class="lg:h-[500px] h-200px w-full lg:mb-16 mb-8">
                <Bar :data="chartData" :options="chartOptions" class="self-center mx-auto w-full"/>
            </div>
            <span class="mb-2 mb:0 font-bold text-lg">
                {{ trophiesPerYear[selectedYear]?.season }}
            </span>
            <input type="range" min="0" :max="trophiesPerYear?.length - 1" v-model="selectedYear" class="range range-xs w-full [--range-shdw:white]" />
        </div>
        
        <div class="flex flex-wrap justify-center gap-16 p-4">
            <div v-for="team in sortedByTrophies" :key="team.team" class="card bg-base-100 w-96 shadow-md">
                <figure>
                    <img :src="require(`../assets/logo/${team.img_url}`)" class="w-10 h-10 object-contain lg:h-16 lg:w-16" alt="">
                </figure>
                <div class="card-body">
                    <h2 class="card-title">
                        {{ team.team }}
                        <div class="badge badge-warning">{{ team.championship_years.split('|').length }}</div>
                    </h2>
                    
                    <div class="flex flex-wrap gap-4 items-center justify-center lg:justify-start">
                        <div v-for="year in team.championship_years.split('|')" :key="year" class="badge badge-sm badge-outline">{{ year }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import axios from 'axios';
    import {ref, onMounted, computed} from 'vue';
    import ChartDataLabels from 'chartjs-plugin-datalabels';
    import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js';
    import { Bar } from 'vue-chartjs';
    import {useHead} from '@vueuse/head';

    useHead({
        title: 'Cyprus League | Trophies',
        meta: [
            {
                name: 'description',
                content: 'Discover the championship titles and trophies won by each team in Cyprus League history.'
            }
        ]
    });
    



    const trophies = ref([]);
    const allTrophies = ref([]);
    const isLoading = ref(true);
    const selectedYear = ref(83);
    const teams = ref([])



    const getPageData = async () => {
        isLoading.value = true;
        try {
            const res = await axios.get('https://cyprus-league-demo.onrender.com/api/trophies-per-team');
            trophies.value = res.data;
        } catch (err) {
            console.error('Something went wrong with fetching data...' + err);
        } finally {
            isLoading.value = false;
        }
    };

    const getTrophies = async () => {
        isLoading.value = true;
        try {
            const res = await axios.get('https://cyprus-league-demo.onrender.com/api/trophies');
            allTrophies.value = res.data;
        } catch (err) {
            console.error('Something went wrong with fetching data...' + err);
        } finally {
            isLoading.value = false;
        }
    };

    const getTeams = async () => {
        isLoading.value = true;
        try {
            const res = await axios.get('https://cyprus-league-demo.onrender.com/api/teams');
            teams.value = res.data;
        } catch (err) {
            console.error('Something went wrong with fetching data...' + err);
        } finally {
            isLoading.value = false;
        }
    };

    const trophiesPerYear = computed(() => {
        let championshipsPerYear = [];
        const cumulativeTitles = {};

        allTrophies.value.forEach(entry => {
            const { season, team } = entry;

            if (!cumulativeTitles[team]) {
                cumulativeTitles[team] = 0;
            }

            cumulativeTitles[team]++;

            championshipsPerYear.push({ season, championship: { ...cumulativeTitles } });
        });

        return championshipsPerYear.map(year => {
            return {
                season: year.season,
                championship: Object.fromEntries(
                    Object.entries(year.championship).sort(([, a], [, b]) => b - a) // Ταξινόμηση κατά φθίνουσα σειρά
                ),
            };
        });
    });


    const teamLabels = computed(() => {
        if (trophiesPerYear.value.length) {
            return Object.keys(trophiesPerYear.value[selectedYear.value]?.championship);
        } else {
            return []
        }
    });

    const teamValues = computed(() => {
        if (trophiesPerYear.value.length) {
            return Object.values(trophiesPerYear.value[selectedYear.value]?.championship);
        } else {
            return []
        }
    });

    const teamColors = computed(() => {
        if (trophiesPerYear.value.length) {
            const teams = Object.keys(trophiesPerYear.value[selectedYear.value]?.championship);
            return teams.map(team => convertTeamColor(team))?.map(team => convertHexToRGBA(team));
        } else {
            return []
        }
    });

    const teamLogos = computed(() => {
        if (trophiesPerYear.value.length) {
            const teams = Object.keys(trophiesPerYear.value[selectedYear.value]?.championship);
            return teams.map(team => converTeamLogo(team) )
        } else {
            return []
        }
    });

    const convertTeamColor = (choosenTeam) => {
        return teams.value?.find(team => team.team === choosenTeam)?.color
    }

    const converTeamLogo = (choosenTeam) => {
        return teams.value?.find(team => team.team === choosenTeam)?.img_url
    }

    const convertHexToRGBA = (hex, alpha = 0.5) => {
            // Αφαιρεί το σύμβολο #
        if (hex) {
            hex = hex?.replace(/^#/, '');
        }

        // Αν το hex είναι 3 χαρακτήρες (π.χ. #f00), το κάνει 6 χαρακτήρες
        if (hex?.length === 3) {
            hex = hex.split('').map(char => char + char).join('');
        }

        // Μετατροπή σε RGB
        const bigint = parseInt(hex, 16);
        const r = (bigint >> 16) & 255;
        const g = (bigint >> 8) & 255;
        const b = bigint & 255;

        // Επιστροφή σε rgba format
        return `rgba(${r}, ${g}, ${b}, ${alpha})`;
    }


    const sortedByTrophies = computed(() => {
        return [...trophies.value].sort((b, a) => {
       
           return a.championship_years.split('|').length - b.championship_years.split('|').length
        })
    });

    const chartData = computed(() => ({
        labels: teamLabels.value, // Labels στον κάθετο άξονα (Y)
        datasets: [
            {
                label: 'Trophies per Team', // Ετικέτα για το dataset
                data: teamValues.value, // Τιμές στον οριζόντιο άξονα (X)
                backgroundColor: teamColors.value,
                datalabels: {
                    display: true
                }
            },
        ],
    }));

    const chartOptions = {
        responsive: true,
        indexAxis: 'y', // Για οριζόντιες μπάρες
        plugins: {
            legend: {
                display: false,
                position: 'top', // Θέση του legend
            },
            tooltip: {
                enabled: false, // Ενεργοποίηση tooltip για hover
            },
            datalabels: {
                anchor: 'end', // Position the labels at the end of the bars
                align: 'end',  // Align labels to the end of the bars
                formatter: (value) => value, // Display the value of each bar
                font: {
                    weight: 'bold',
                },
            },
        },
        scales: {
            x: {
                beginAtZero: true, // Ο άξονας X να ξεκινά από το 0
                max: 35
            },
        },
    };



    ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ChartDataLabels)
    onMounted(() => {
        getPageData();
        getTrophies();
        getTeams();
    });


</script>