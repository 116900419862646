<template>
    <NavBar></NavBar>
    <!-- <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link> -->
  <router-view class=""/>
</template>

<script setup>
  import { computed, reactive } from 'vue';
  import NavBar from './components/NavBar.vue';
  import { useHead } from '@vueuse/head';

  const siteData = reactive({
    title: 'Cyprus League',
    description: 'Discover Cyprus League, your ultimate destination for football statistics, top scorers, and match data. Join now and stay ahead in the game!'
  });

  useHead({
    title: computed(() => siteData.title),
    meta: [
      {
        name: 'description',
        content: computed(() => siteData.description)

      }
    ]
  })

</script>

