<template>
    <div class="w-full flex flex-col items-center p-4 lg:mt-16">
        <!-- Filters -->
        <div class="flex flex-col lg:flex-row lg:gap-20 gap-6 w-full justify-center lg:mb-48 mb-16">

            <label class="form-control ">
                <div class="label">
                    <span class="label-text">Season</span>
                </div>
                <select class="select select-xs lg:select-md select-ghost " v-model="seasonSelected" @change="updateSeason">
                    <option value="">All Seasons</option>
                    <option v-for="season in uniqueSeasons" :key="season" :value="season">{{ season }}</option>
                </select>
            </label>
    
            <label class="form-control">
                <div class="label">
                    <span class="label-text">Phase</span>
                </div>
                <select class="select select-xs lg:select-md select-ghost" v-model="phaseSelected" @change="updatePhase">
                    <option value="">All Phases</option>
                    <option v-for="phase in uniquePhases" :key="phase" :value="phase">{{ phase }}</option>
                </select>
            </label>

            <label class="form-control">
                <div class="label">
                    <span class="label-text ">Round</span>
                </div>
                <select class="select select-xs lg:select-md select-ghost" v-model="roundSelected" @change="updateRound">
                    <option value="">All Rounds</option>
                    <option v-for="round in uniqueRounds" :key="round" :value="round">{{ round }}</option>
                </select>
            </label>
            
            <label class="form-control">
                <div class="label">
                    <span class="label-text">Filter by Home/Away</span>
                </div>
                <select class="select select-xs lg:select-md select-ghost" v-model="homeTeamSelected" @change="updateHomeTeam">
                    <option value="">All Matches</option>
                    <option :value="team1?.team">Home Team - {{ team1?.team }}</option>
                    <option :value="team2?.team">Home Team - {{ team2?.team }}</option>
                    <!-- <option v-for="phase in uniquePhases" :key="phase" :value="phase">{{ phase }}</option> -->
                </select>
            </label>
        </div>

        <!-- Scores -->
        <Transition>
            <div v-if="team1 && team2" class="grid grid-cols-3 gap-x-10 lg:gap-x-4 items-end text-2xl lg:text-6xl lg:mb-16 mb-8 w-full lg:w-2/3">

                <div class="flex flex-col gap-6 justify-center items-center">
                    <img :src="require(`../assets/logo/${team1.img_url}`)" class="w-10 h-10 object-contain lg:h-16 lg:w-16" alt="">
                    <span class="">{{ scores?.homeTeamWins}}</span>
                </div>

            
                <div class="flex flex-col gap-8 justify-center items-center">
                    <span>X</span>
                    <span class="">{{ scores?.draws }}</span>
                </div>

            
                <div class="flex flex-col gap-6 justify-center items-center">
                    <img  :src="require(`../assets/logo/${team2.img_url}`)" class="w-10 h-10 object-contain lg:h-16 lg:w-16" alt="">
                    <span class="">{{ scores?.awayTeamWins }}</span>
                </div>

            </div>
            <div v-else class="mb-16 lg:mt-16 w-full lg:w-2/3">
                <h1 class="text-center lg:text-4xl text-gray-500 text-opacity-70">ΕΠΕΛΕΞΕ 2 ΟΜΑΔΕΣ</h1>
            </div>
        </Transition>


        <!-- Goals -->
        <Transition>
            <div v-if="team1 && team2" class="grid grid-cols-3 gap-x-4 text-xl mb-32">

                <div class="flex flex-col gap-8 justify-center items-center">

                    <span class="">{{ goals?.homeTeamGoals}}</span>
                </div>
            
                <div class="flex flex-col gap-8 items-center justify-center">
                    <span class="text-xl">GOALS</span>

                </div>
            
                <div class="flex flex-col gap-8 justify-center items-center">
                    <span class="">{{ goals?.awayTeamGoals }}</span>
                </div>

            </div>
        </Transition>

        <!-- Footer -->
        <span class="text-xs text-gray-500 pb-2 text-opacity-70 text-center bottom-0 fixed"> Data includes matches from the 1998-1999 season onward.</span>
    </div>
</template>


<script setup>
    import {ref} from 'vue';
    const props = defineProps(['uniquePhases', 'uniqueSeasons', 'uniqueRounds', 'team1', 'team2', 'scores', 'goals'])
    const emit = defineEmits(['set-season', 'set-phase', 'set-homeTeam', 'set-round'])
    const seasonSelected = ref('');
    const phaseSelected = ref('');
    const homeTeamSelected = ref('');
    const roundSelected = ref('');

    const updateSeason = () => {
        emit('set-season', seasonSelected.value)
    }

    const updatePhase = () => {
        emit('set-phase', phaseSelected.value)
    }

    const updateHomeTeam = () => {
        emit('set-homeTeam', homeTeamSelected.value)
    }

    const updateRound = () => {

        emit('set-round', roundSelected.value)
    }

</script>

<style scoped>
    /* we will explain what these classes do next! */
    .v-enter-active {
    transition: opacity 0.5s ease;
    }

    .v-enter-from,
    .v-leave-to {
    opacity: 0;
    }


</style>