import { createRouter, createWebHistory } from 'vue-router'
import HomePage from '@/pages/HomePage.vue'
import TopScorers from '../pages/TopScorers.vue'
import IdrisiKop from '@/pages/IdrisiKop.vue';
import TrophiesPage from '@/pages/TrophiesPage.vue';

const routes = [
  {
    path: "/",
    name: "home",
    component: HomePage,
  },
  {
    path: "/top-scorers",
    name: "TopScorers",

    component: TopScorers,
  },
  {
    path: "/idrisi",
    name: "idrisi",

    component: IdrisiKop,
  },
  {
    path: "/trophies",
    name: "trophies",

    component: TrophiesPage,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
