<template>
    <div class="lg:pt-[80px] pb-[100px] lg:px-56 px-4 mt-16 flex flex-col gap-20">
        <h1 class="text-xl">Οι 8 ομάδες που ίδρυσαν την ΚΟΠ το 1934 στις 23 Σεπτεμβρίου</h1>
        <div v-for="team in teams" :key="team.name" class="flex lg:flex-row flex-col shadow-lg p-4 items-center gap-8">
            <img :src="require(`../assets/logo/${team.img}`)" alt="" class="lg:w-20 lg:h-20 w-14 h-14 object-contain">
            <div class="flex flex-col gap-2">
                <h1 class="lg:text-3xl text-xl" :class="`text-${team.color}`">{{ team.name }}</h1>
                <span class="lg:text-lg lg:text-justify">{{ team.story }}</span>
                <hr>
                <span class="text-lg">{{ team.trophies }}</span>
            </div>
        </div>

    </div>
</template>

<script setup>
    import {useHead} from '@vueuse/head';
    useHead({
            title: 'Cyprus League | ΚΟΠ',
            meta: [
                {
                    name: 'description',
                    content: 'The eight teams that founded cyprus league'
                }
            ]
        });
const teams = [
    {
        img: 'anorthosi.png',
        color: 'blue-600',
        name: 'Ανόρθωση',
        story: 'Η Ανόρθωση αποτελεί το πιο παλιό, ποδοσφαιρικό σωματείο στην Κύπρο. Ιδρύθηκε το 1911 και είναι μαζί με τον ΑΠΟΕΛ οι μόνες 2 από τις ιδρυτικές ομάδες που δεν υποβιβάστηκαν ποτέ στην Β’ Κατηγορία (εκτός της επανασύστασης το 1945-46 αφού είχε διαλυθεί το 1937). Η Ανόρθωση Αμμοχώστου είναι η πρώτη κυπριακή ομάδα που συμμετείχε σε φάση ομίλων ευρωπαϊκής διοργάνωσης και συγκεκριμένα στους ομίλους του ΟΥΕΦΑ Τσάμπιονς Λιγκ 2008-09.',
        trophies: 'Κατέκτησε 13 πρωταθλήματα, 11 κύπελλα και 7 ασπίδες της ΚΟΠ.'
    },
    {
        img: 'trust.png',
        color: 'yellow-600',
        name: 'Τραστ',
        story: 'Η Ένωσις Νέων Τράστ, ιδρύθηκε το 1924. Πρόκειται για την πρώτη νταμπλούχο της Κύπρου αφού κατέκτησε το πρωτάθλημα και το κύπελλο του 1934. Το 1938 το σωματείο διέλυσε την ποδοσφαιρική του ομάδα αφού θεωρούσε πως τύγχανε άδικης μεταχείρισης από την ΚΟΠ σε κόντρα της με τον ΑΠΟΕΛ.',
        trophies: 'Κατέκτησε 1 πρωτάθλημα και 3 κύπελλα.'
    },
    {
        img: 'apoel.png',
        color: 'orange-600',
        name: 'ΑΠΟΕΛ',
        story: 'Το ΑΠΟΕΛ ιδρύθηκε το 1926 στη Λευκωσία, ως Ποδοσφαιρικός Όμιλος Ελλήνων Λευκωσίας (ΠΟΕΛ), με στόχο τη δημιουργία ενός σωματείου που θα εκπροσωπούσε μόνο Έλληνες της Κύπρου. Εμπνευστής ήταν ο Διομήδης Συμεωνίδης, ενώ η ίδρυση πραγματοποιήθηκε με τη στήριξη του Γεωργίου Πούλια. Το ΑΠΟΕΛ είναι γνωστό για τις διεθνείς επιτυχίες του, έφτασε στους "8" του UEFA Champions League.',
        trophies: 'Κατέκτησε 29 πρωταθλήματα, 21 κύπελλα και 14 ασπίδες της ΚΟΠ.'
    },
    {
        img: 'ael.png',
        color: 'yellow-600',
        name: 'ΑΕΛ',
        story: 'Η ΑΕΛ ιδρύθηκε το 1930 και με εξαίρεση τη σεζόν 1968-69 που αγωνιζόταν στην Α’ Εθνική συμμετέχει συνέχεια στα πρωταθλήματα της ΚΟΠ.',
        trophies: 'Κατέκτησε 6 πρωταθλήματα, 7 κύπελλα και 4 ασπίδες της ΚΟΠ.'
    },
    {
        img: 'aris.png',
        color: 'green-600',
        name: 'Άρης',
        story: 'Η “ελαφρά ταξιαρχία” ιδρύθηκε το 1930 και κατέκτησε τον πρώτο της τίτλο το 2023.',
        trophies: 'Κατέκτησε 1 πρωτάθλημα και 1 ασπίδα της ΚΟΠ.'
    },
    {
        img: 'olympiakos.png',
        color: 'green-600',
        name: 'Ολυμπιακός',
        story: 'Ο Ολυμπιακός ιδρύθηκε το 1931 και είναι από τα παραδοσιακά σωματεία της Κύπρου. Σήμερα βρίσκεται στην 2η κατηγορία.',
        trophies: 'Κατέκτησε 3 πρωταθλήματα, 1 κύπελλο και 1 ασπίδα της ΚΟΠ.'
    },
    {
        img: 'cetinkaya.png',
        color: 'red-600',
        name: 'Τουρκική Λέσχη (Τσετίνκαγια)',
        story: 'Ιδρύθηκε το 1930 σαν Τουρκικός Αθλητικός Σύλλογος Λευκωσίας αλλά έμεινε γνωστή ως “Τουρκική Λέσχη Λευκωσίας”. (ή με το τούρκικο όνομα της Τσετίνκαγια). Είναι η μοναδική τούρκικη ομάδα – ιδρυτικό μέλος της ΚΟΠ που συμμετείχε στα πρωταθλήματα μέχρι το 1955. Την συγκεκριμένη χρονιά, όλες οι τούρκικες ομάδες αποχώρησαν από την ΚΟΠ.',
        trophies: 'Κατέκτησε 1 πρωτάθλημα, 2 κύπελλα και 3 ασπίδες της ΚΟΠ.'
    },
    {
        img: 'epa.png',
        color: 'yellow-600',
        name: 'ΕΠΑ',
        story: 'Η ΕΠΑ Λάρνακας ιδρύθηκε το 1932 και υπήρξε σημαντική δύναμη μέχρι τη διάλυσή της το 1994. Ιστορία που συνεχίζεται μέσω της ΑΕΚ Λάρνακας.',
        trophies: 'Κατέκτησε 3 πρωταθλήματα, 5 κύπελλα και 1 ασπίδα της ΚΟΠ.'
    }
];


</script>