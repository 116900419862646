<template>


    <div v-if="!isMobile" class="flex items-center justify-between w-full bg-base-200 shadow-lg h-[80px] text-base-content fixed top-0 z-50 px-8">
        <span class="font-medium text-2xl">CyprusLeague</span>
        <div class="flex gap-16 text-lg">
            <RouterLink to="/" :class="{ 'text-primary': $route.path === '/' }">Head 2 Head </RouterLink>
            <RouterLink to="/top-scorers" :class="{ 'text-primary': $route.path === '/top-scorers' }">Top Scorers</RouterLink>
            <RouterLink to="/trophies" :class="{ 'text-primary': $route.path === '/trophies' }">Trophies</RouterLink>
            <RouterLink to="/idrisi" :class="{ 'text-primary': $route.path === '/idrisi' }">ΚΟΠ</RouterLink>
        </div>
        <div></div>
    </div>

    <div v-else class="navbar bg-base-100 shadow-lg top-0 z-50 h-[80px] relative px-0">
        <div class="flex-1">
            <a class="btn btn-ghost text-xl">CyprusLeague</a>
        </div>
        <div class="flex-none">
            <label class="btn btn-circle btn-md swap swap-rotate">
            <!-- this hidden checkbox controls the state -->
            <input type="checkbox" v-model="checkNavbar"/>

            <!-- hamburger icon -->
            <svg
                class="swap-off fill-current"
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                viewBox="0 0 512 512">
                <path d="M64,384H448V341.33H64Zm0-106.67H448V234.67H64ZM64,128v42.67H448V128Z" />
            </svg>

            <!-- close icon -->
            <svg
                class="swap-on fill-current"
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                viewBox="0 0 512 512">
                <polygon
                points="400 145.49 366.51 112 256 222.51 145.49 112 112 145.49 222.51 256 112 366.51 145.49 400 256 289.49 366.51 400 400 366.51 289.49 256 400 145.49" />
            </svg>
            </label>
        </div>
        <div v-if="checkNavbar" class="absolute shadow-lg bg-base-200 top-[80px] w-full h-[400px] flex flex-col text-2xl gap-16 p-16 max-h-screen overflow-y-auto">
            
            <RouterLink to="/" @click="checkNavbar = false" :class="{ 'text-primary': $route.path === '/' }">Head 2 Head </RouterLink>
            <RouterLink to="/top-scorers" @click="checkNavbar = false" :class="{ 'text-primary': $route.path === '/top-scorers' }">Top Scorers</RouterLink>
            <RouterLink to="/trophies" :class="{ 'text-primary': $route.path === '/trophies' }">Trophies</RouterLink>
            <RouterLink to="/idrisi" @click="checkNavbar = false" :class="{ 'text-primary': $route.path === '/idrisi' }">ΚΟΠ</RouterLink>
        </div>
    </div>

    <div 
        v-if="checkNavbar" 
        class="fixed inset-0 bg-gray-900 bg-opacity-50 z-40 blur-2xl overflow-y-hidden"
        @click="checkNavbar = false">
    </div>

</template>

<script setup>
    import { ref, onMounted, onUnmounted, watch } from 'vue';

    const checkNavbar = ref(false);

    const isMobile = ref(window.innerWidth <= 768); // Define your mobile breakpoint

    const updateViewport = () => {
        isMobile.value = window.innerWidth <= 768;
    };


    watch(checkNavbar, (newValue) => {
        if (newValue) {
            document.body.classList.add('overflow-hidden');
        } else {
            document.body.classList.remove('overflow-hidden');
        }
    });

    onMounted(() => {
        window.addEventListener('resize', updateViewport);
        updateViewport();
    });

    onUnmounted(() => {
        window.removeEventListener('resize', updateViewport);
        document.body.classList.remove('overflow-hidden');
    });

</script>