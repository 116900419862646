<template>

    <div class="flex flex-col lg:pt-[80px] pb-[100px] lg:px-16 px-4">
        <div class="flex gap-12 items-center mt-16">
            <label class="form-control w-1/4 mb-16">
                <div class="label">
                    <span class="label-text">Team</span>
                </div>
                <select class="select select-xs lg:select-md select-ghost" v-model="teamSelected">
                    <option value="">All Teams</option>
                    <option v-for="team in uniqueTeams" :key="team" :value="team">{{ team }}</option>
                </select>
            </label>
            <label class="form-control w-1/4 mb-16">
                <div class="label">
                    <span class="label-text">Nationality</span>
                </div>
                <select class="select select-xs lg:select-md select-ghost" v-model="nationSelected">
                    <option value="">All Nationalities</option>
                    <option v-for="nation in uniqueNation" :key="nation" :value="nation">{{ nation }}</option>
                </select>
            </label>
            <label class="form-control w-1/4 mb-16">
                <div class="label">
                    <span class="label-text">Sort by</span>
                </div>
                <select class="select select-xs lg:select-md select-ghost" v-model="sortBySelected" :disabled="tabSelected === 'teams'">
                    <option value="season_name">Season</option>
                    <option value="team_name">Team</option>
                    <option value="goals">Goals</option>
                </select>
            </label>
        </div>
        <div role="tablist" class="tabs tabs-bordered w-1/4 mb-4 tabs-lg">
            <a role="tab" @click="toggleTabs('players')" class="tab" :class="tabSelected === 'players' ? 'tab-active' : ''">Top Scorers</a>
            <a role="tab" @click="toggleTabs('teams')" class="tab" :class="tabSelected === 'teams' ? 'tab-active' : ''">Top Scorers Per Team</a>
        </div>

        <div v-if="tabSelected === 'players'" class="grid grid-cols-2 lg:grid-cols-6 gap-y-8 gap-x-4">
            <PlayerCard v-for="scorer in filteredTopScorers" :key="scorer.id" :scorer="scorer"/>
        </div>
        <div v-if="tabSelected === 'teams'" class="grid grid-cols-2 lg:grid-cols-6 gap-y-24 gap-x-4 mt-16">
            <div v-for="team in topScorersPerTeam" :key="team.team">
                <div class="flex gap-4 items-center justify-center h-20">
                    <img :src="require(`../assets/logo/${team.team}`)" alt="" class="lg:w-20 lg:h-20 w-14 h-14 object-contain items-center">
                    <span class="self-center text-4xl">{{ team.top_scorers }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import axios from 'axios';
    import {onMounted, ref, computed} from 'vue';
    import PlayerCard from '../components/PlayerCard';
    import {useHead} from '@vueuse/head';
    const topScorers = ref([]);
    const teamSelected = ref('');
    const nationSelected = ref('');
    const sortBySelected = ref('season_name');
    const tabSelected = ref('players');
        

    useHead({
        title: 'Cyprus League | Top Scorers',
        meta: [
            {
                name: 'description',
                content: 'Discover the top scorers in the Cyprus League.'
            }
        ]
    });
    

    const getTopScorers = async() => {
        const res = await axios.get('https://cyprus-league-demo.onrender.com/api/topScorers');
        topScorers.value = res.data;
    };

    const filteredTopScorers = computed(() => {
        let newScorers = topScorers.value;
        const property = sortBySelected.value;
        if (teamSelected.value) {
            newScorers = newScorers.filter(scorer => scorer.team_name === teamSelected.value);
        }

        if (nationSelected.value) {
            newScorers = newScorers.filter(scorer => scorer.nationality_names.split(',').includes(nationSelected.value))
        }
    
        return newScorers.sort((a, b) => {
            if (typeof a[property] === 'number') {
                return b[property] - a[property]
            } else {
                return a[property].localeCompare(b[property])
            }
        });
    })

    const uniqueTeams = computed(() => {
        const teams = topScorers.value.map(scorer => scorer.team_name);
        return [... new Set(teams)].sort();
    });

    const uniqueImages = computed(() => {
        const images = topScorers.value.map(scorer => scorer.img);
        return [... new Set(images)].sort();
    });


    const uniqueNation = computed(() => {
        const nations = topScorers.value.map(scorer => scorer.nationality_names.split(','));
        return [... new Set(nations.flat())].sort();
    });

    onMounted(() => {
        getTopScorers();
    });

    const toggleTabs = (select) => {
        if (select === tabSelected.value) return;
        tabSelected.value = select;
    }

    const topScorersPerTeam = computed(() => {
        const list = []
        uniqueImages.value.forEach(team => {
            list.push({team, top_scorers: 0})
            filteredTopScorers.value.forEach(scorer => {
                if (scorer.img === team) {
                   const teamEntry = list.find(entry => entry.team === team);
                   teamEntry.top_scorers++; 
                }
            });


        });


        return list.sort((a,b) => b['top_scorers'] - a['top_scorers']);
    })



</script>