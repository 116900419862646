<template>

    <div class="flex">
        <!-- Sidebar -->
        <SideBar :teams="teams" :team1="team1" :team2="team2" @choose-team="chooseTeam" @unchoose-team="unchooseTeam"/>

        <div class="flex flex-col w-full items-center lg:justify-center">
            <!-- Main Content -->
            <MainContent 
                :team1="team1" 
                :team2="team2" 
                :uniqueSeasons="uniqueSeasons" 
                :uniquePhases="uniquePhases" 
                :uniqueRounds="uniqueRounds"
                :goals="goals" 
                :scores="scores" 
                @set-season="setSeasonSelected"
                @set-phase="setPhaseSelected"
                @set-homeTeam="setHomeTeamSelected"
                @set-round="setRoundSelected"/>
        </div>
    </div>
</template>

<script setup>
    import NavBar from '@/components/NavBar.vue';
    import axios from 'axios';
    import { computed, onMounted, ref } from 'vue';
    import SideBar from '../components/SideBar.vue'
    import MainContent from '@/components/MainContent.vue';
    import {useHead} from '@vueuse/head';

    useHead({
        title: 'Cyprus League | Head2Head',
        meta: [
            {
                name: 'description',
                content: 'Explore detailed Head-to-Head statistics for teams in Cyprus League, including historical results.'
            }
        ]
    });

    const teams = ref([]);

    const team1 = ref('');
    const team2 = ref('');
    // const team1 = ref({ "id": 5, "team": "Omonia", "city": "Leukosia", "img_url": "omonoia_fc.png" } );
    // const team2 = ref({ "id": 4, "team": "Nea Salamina", "city": "Amoxostos", "img_url": "nea_salamina.png" });
    const matches = ref([]);
    const seasonSelected = ref('');
    const phaseSelected = ref('');
    const roundSelected = ref('');
    const homeTeamSelected = ref('');


    const getTeams = async () => {
        const res = await axios.get('https://cyprus-league-demo.onrender.com/api/teams');
        teams.value = res.data;
    };

    const getData = async() => {
        const res = await axios.get(`https://cyprus-league-demo.onrender.com/api/all-head-2-head`);
        matches.value = res.data;
    };




    const resetFitlers = () => {
        seasonSelected.value = '';
        phaseSelected.value = '';
        homeTeamSelected.value = '';
    }

    const matchesBetween = computed(() => {
        let newMatches = [];
        if (team1.value && team2.value) {
            newMatches =  matches.value.filter(match => {
                const isTeam1VsTeam2 = (match.home_team_name === team1.value.team && match.away_team_name === team2.value.team);
                const isTeam2VsTeam1 = (match.home_team_name === team2.value.team && match.away_team_name === team1.value.team);
                return isTeam1VsTeam2 || isTeam2VsTeam1;
            });
        }
        return newMatches; 
    })

    const filteredMatches = computed(() => {
        let newMatches = [];
        if (team1.value && team2.value) {
            newMatches =  matches.value.filter(match => {
                const isTeam1VsTeam2 = (match.home_team_name === team1.value.team && match.away_team_name === team2.value.team);
                const isTeam2VsTeam1 = (match.home_team_name === team2.value.team && match.away_team_name === team1.value.team);
                return isTeam1VsTeam2 || isTeam2VsTeam1;
            });
     
            if (seasonSelected.value) {
                newMatches = newMatches.filter(match => match.season_name === seasonSelected.value);
            }

            if (phaseSelected.value) {
                newMatches = newMatches.filter(match => match.phase_name === phaseSelected.value);
            }

            if (roundSelected.value) {
                newMatches = newMatches.filter(match => match.round === roundSelected.value)
            }

            if (homeTeamSelected.value) {
                newMatches = newMatches.filter(match => match.home_team_name === homeTeamSelected.value);
                if (homeTeamSelected.value === team2.value.team) {
                    [team1.value, team2.value] = [team2.value, team1.value];
                }
            }

            return newMatches;
        } else {
            return [];
        }
    });


    const uniqueSeasons = computed(() => {
        const seasons = matchesBetween.value.map(match => match.season_name);
        return [...new Set(seasons)].sort();
    });

    const uniquePhases = computed(() => {
        const phases = matchesBetween.value.map(match => match.phase_name);
        return [...new Set(phases)].sort();
    });

    const uniqueRounds = computed(() => {
        const rounds = matchesBetween.value.map(match => match.round);
        return [...new Set(rounds)].sort();
    })


    const scores = computed(() => {
        let homeTeamWins = 0;
        let draws = 0;
        let awayTeamWins = 0;
        
        
        if(filteredMatches.value.length === 0) return {homeTeamWins, draws, awayTeamWins};

        
        filteredMatches.value.forEach(match => {
            if (match.home_team_name === team1.value.team) {
                homeTeamWins += parseInt(match.result_home) >  parseInt(match.result_away) ? 1 : 0;
                draws += parseInt(match.result_home) ===  parseInt(match.result_away) ? 1 : 0;
                awayTeamWins += parseInt(match.result_home) <  parseInt(match.result_away) ? 1 : 0;
            } else if (match.home_team_name === team2.value.team) {
                homeTeamWins += parseInt(match.result_home) <  parseInt(match.result_away) ? 1 : 0;
                draws += parseInt(match.result_home) ===  parseInt(match.result_away) ? 1 : 0;
                awayTeamWins += parseInt(match.result_home) >  parseInt(match.result_away) ? 1 : 0;
            }
        });

        return {homeTeamWins, draws, awayTeamWins};
    });

    const goals = computed(() => {
        let homeTeamGoals = 0;
        let awayTeamGoals = 0;
        
        
        if(filteredMatches.value.length === 0) return {homeTeamGoals, awayTeamGoals};

        
        filteredMatches.value.forEach(match => {
            if (match.home_team_name === team1.value.team) {
                homeTeamGoals += parseInt(match.result_home);
                awayTeamGoals += parseInt(match.result_away);
            } else if (match.home_team_name === team2.value.team) {
                homeTeamGoals += parseInt(match.result_away);
                awayTeamGoals += parseInt(match.result_home);
            }
        });

        return {homeTeamGoals, awayTeamGoals};
    });


	const chooseTeam = (newTeam) => {
        // resetFitlers();
        if (team1.value && team2.value) {
            team1.value = null;
            team2.value = null;
        }
        if (!team1.value) {
            team1.value = newTeam;
        } else if (!team2.value) {
            team2.value = newTeam;
        }

    }

    const unchooseTeam = (oldTeam) => {
        if (team1.value === oldTeam) {
            team1.value = '';
        } else if (team2.value === oldTeam) {
            team2.value = '';
        }
    }

    const setSeasonSelected = (season) => {
        seasonSelected.value = season;
    };

    const setPhaseSelected = (phase) => {
        phaseSelected.value = phase;
    };

    const setRoundSelected = (round) => {
        console.log('OKAY')
        roundSelected.value = String(round);
    }

    const setHomeTeamSelected = (homeTeam) => {
        homeTeamSelected.value = homeTeam;
    };
    
    onMounted(() => {
        getTeams();
        getData();
    });

</script>

<style scoped>
    /* we will explain what these classes do next! */
    .v-enter-active {
    transition: opacity 0.5s ease;
    }

    .v-enter-from,
    .v-leave-to {
    opacity: 0;
    }


</style>