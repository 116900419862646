<template>
	<div class="w-1/3 flex flex-col py-8 px-4 bg-base-200 gap-12 shadow-lg">
	    
		<label class="input input-bordered input-sm flex items-center gap-2 col-span-3 w-3/3">
			<input type="text" class="grow" placeholder="Search" v-model="searchTeamQuery"/>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				viewBox="0 0 16 16"
				fill="currentColor"
				class="h-4 w-4 opacity-70">
				<path
				fill-rule="evenodd"
				d="M9.965 11.026a5 5 0 1 1 1.06-1.06l2.755 2.754a.75.75 0 1 1-1.06 1.06l-2.755-2.754ZM10.5 7a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Z"
				clip-rule="evenodd" />
			</svg>
		</label>

		<div class="overflow-auto grid grid-cols-1 lg:grid-cols-3 gap-y-4 gap-x-2 h-screen-minus-100">
			<div v-for="team in filteredTeams" 
				@click="isTeamSelected(team) ? $emit('unchoose-team', team) : $emit('choose-team', team)"
				:key="team.id" 
				class="flex rounded-lg flex-col h-32 cursor-pointer hover:border-purple-900 hover:border-2" 
				:class="isTeamSelected(team) ? 'bg-purple-900' : ''" >
				<div class=" h-24 flex flex-col items-center justify-center rounded-full">
					<img :src="require(`../assets/logo/${team.img_url}`)" class="w-12 h-12 object-contain" :alt="'Logo of the: ' + team.team">
				</div>
				<span class="w-full text-center text-xs">{{ team.team }}</span>
			</div>
		</div>
	</div>
</template>

<script setup>
	import { computed, ref } from 'vue';

	const props = defineProps(['teams', 'isTeamSelected', 'team1', 'team2']);
	const searchTeamQuery = ref('');


	const filteredTeams = computed(() => {
		if (searchTeamQuery.value.length < 1) return props.teams.slice().sort((a, b) => a.team.localeCompare(b.team));
			return props.teams
				.filter(team => team.team.toLowerCase().includes(searchTeamQuery.value.toLowerCase()))
				.sort((a, b) => a.team.localeCompare(b.team)); // Sort alphabetically
    });



    const isTeamSelected = (newTeam) => {
        return newTeam === props.team1 || newTeam === props.team2;
    };

</script>

<style scoped>

.h-screen-minus-100 {
  height: calc(100vh - 145px);
}

</style>
