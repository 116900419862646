<template>
    <div class="p-4 shadow-md rounded-lg flex flex-col justify-between">
        <span class="text-center text-lg font-bold">{{ scorer.player_name }}</span>
        <div class="mb-10 flex gap-2 items-center justify-center">
            <img v-for="n in nationalities" :key="n" :src="`https://flagsapi.com/${n}/flat/64.png`" class="w-8 self-center">
        </div>
       
     

        <span class="text-center text-4xl font-bold mb-10">⚽{{ scorer.goals }}</span>
        <img :src="require(`../assets/logo/${scorer.img}`)" alt="" class="w-10 h-10 object-contain self-center mb-4">
        <span class="text-center text-lg">{{ scorer.season_name }}</span>
    </div>
</template>

<script setup>
    import {computed} from 'vue';

    const props = defineProps(['scorer']);


    const nationalities = computed(() => {
        return props.scorer.nationality_codes.split(',') });

</script>